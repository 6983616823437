<script setup>
// import Select from '@/components/Select.vue'
// import ComboBox from '@/components/ComboBox.vue'

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  TransitionRoot
} from '@headlessui/vue'

import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid/index'

import { DateTime } from 'luxon'

import { computed, reactive, watch, ref, watchEffect } from 'vue'

import { useStore } from 'vuex'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { dateTimeLocale } from '@/utils'

const props = defineProps({
  'value': {
    required: true,
    type: Object
  }
})
const emit = defineEmits(['input'])

 

const mystery = reactive(ref({
  date: '',
  spectacle_id: ''
}))

// watch(mystery, async (val) => {
//   emit('input', val)
// })

const rules = {
  // firstName: { required }, // Matches state.firstName
  // secondName: {},
  // lastName: { required }, // Matches state.firstName
  // phone: { required }, // Matches state.contact.email
  // email: { email } // Matches state.contact.email
}

const v$ = useVuelidate(rules, mystery)
const store = useStore()

watch(mystery, (val) => {
  emit('input', val)
})

watchEffect(() => {
  if (props.value) {
    mystery.value = props.value
    // date.value = props.value.date
  }

  // if (spectacle.value) {
  //   mystery.value.spectacle_id = spectacle.value.id
  // }
 
})

// watch(spectacle, async (val) => {
//   mystery.value = val
// })

// DateTime.fromISO(mystery.date).toUTC()

// const spectacles = computed(() => {
//   return store.getters['mystery/GET_SPECTACLES']
// })

// const selectedPerson = ref(people[0])
const query = ref('')

const spectacles = reactive(ref([]))
const spectacle = ref({
  id: '',
  title: '',
  // spectacle: {
  //   id: '',
  //   title: ''
  // }
})
const date = ref({})

const test = async () => {
  spectacles.value  = await store.dispatch('mystery/fetchSpectacles', { search: '' })
}

test()

const filteredSpectacles = computed(() => 
// {
  // return spectacles.value
  query.value == ""
    ? spectacles.value
    : spectacles.value.filter((o) => {
      return o.title.toLowerCase().includes(query.value.toLowerCase().replace(/\s+/g, ''))
    })
// }
)

const tmp2 = () => {
  // console.log(val)
  mystery.value.date = dateTimeLocale(date.value)
}

watch(spectacle, (val) => {
  mystery.value.spectacle_id = val.id
  // mystery.value.spectacle = val
})

// const tmp3 = (val) => {
//   console.log(val)
//   // mystery.value.date = dateTimeLocale(date.value)
// }

defineExpose({ v$ })

</script>
<template>
  <!-- <pre>{{ props.value }}</pre> -->
  <!-- <pre>{{ filteredSpectacles }}</pre> -->

  <!-- <button @click="test">test()</button> -->
  <!-- <pre>{{ spectacles }}</pre> -->

  <div class="grid grid-cols-6 gap-6">
    <div class="col-span-6">
      <label for="spectacle" class="block text-sm font-medium text-gray-700">Спектакль</label>

      <Combobox v-model="spectacle">
        <div class="relative mt-1">
          <div
            class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <ComboboxInput :displayValue="(spectacle) => spectacle.title" @change="query = $event.target.value" />
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>
          </div>
          <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0"
            @after-leave="query = ''">
            <ComboboxOptions
              class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ComboboxOption v-for="spectacle in filteredSpectacles" :key="spectacle.id" :value="spectacle">
                {{ spectacle.title }}
              </ComboboxOption>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
      </Combobox>

      <!-- <ComboBox /> -->
      <!-- <Select :items="spectacles">
        <template #item="itemProps">
          <div>
            {{ itemProps.value.title }}
          </div>
        </template>
      </Select> -->
      <!-- <input v-model="contact.lastName" type="text" name="lastName" id="lastName" autocomplete="lastName"
        :class="{ 'input-error': v$.lastName.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.lastName.$silentErrors.length != 0">
          <li v-for="err in v$.lastName.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div> -->
    </div>
    <!-- {{ DateTime.fromISO(mystery.date).toFormat("yyyy-MM-dd'T'HH:mm") }} -->
    <div class="col-span-6 ">
      <label for="date">Дата</label>
      <input v-model="date" type="datetime-local" id="date" name="date"
        @change="tmp2" />

      <!-- <input v-model="contact.firstName" type="text" name="firstName" id="firstName" autocomplete="firstName"
        :class="{ 'input-error': v$.firstName.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.firstName.$silentErrors.length != 0">
          <li v-for="err in v$.firstName.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div> -->
    </div>
    <!-- <div class="col-span-6">
      <label for="secondName" class="block text-sm font-medium text-gray-700">Отчество</label>
      <input v-model="contact.secondName" type="text" name="secondName" id="secondName" autocomplete="secondName"
        :class="{ 'input-error': v$.secondName.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.secondName.$silentErrors.length != 0">
          <li v-for="err in v$.secondName.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div> -->

    <!-- <div class="col-span-6">
      <label for="phone" :class="{ 'input-error': v$.phone.$silentErrors.length != 0 }">Телефон</label>
      <input v-model="contact.phone" type="text" name="phone" id="phone" autocomplete="given-name"
        :class="{ 'input-error': v$.phone.$silentErrors.length != 0, 'focus:border-indigo-500 focus:ring-indigo-500': v$.phone.$silentErrors.length == 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.phone.$silentErrors.length != 0">
          <li v-for="err in v$.phone.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div> -->

    <!-- <div class="col-span-6">
      <label for="email-address" :class="{ 'input-error': v$.email.$silentErrors.length != 0 }">Email address</label>
      <input v-model="contact.email" type="text" name="email-address" id="email-address" autocomplete="email"
        :class="{ 'input-error': v$.email.$silentErrors.length != 0 }" />
      <div class="text-sm text-red-500">
        <ul v-if="v$.email.$silentErrors.length != 0">
          <li v-for="err in v$.email.$silentErrors" :key="err.$uuid">{{ err.$message }}</li>
        </ul>
      </div>
    </div> -->


  </div>

</template>